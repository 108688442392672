import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import InternalLayout from '../components/InternalLayout'
import Head from '../components/Head'


const NotFound = () => {
    return (
        <Layout>
            <Head title='404' />
            <InternalLayout>
                <h1>Page not foung</h1>
                <p>Navigate back to <Link to="/"> Home</Link></p>
            </InternalLayout>
        </Layout>
    )
}

export default NotFound