import React from "react"
import { Link } from "gatsby"
import internalHeaderStyle from "./internal-header.module.scss"

const InternalHeader = () => (
  <header className={internalHeaderStyle.internalHeader}>
    <Link to="/" className={internalHeaderStyle.backNav}>
      <svg
        width="15"
        height="12"
        viewBox="0 0 15 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 6H9H15M1 6L6 1M1 6L6 11" stroke="#81B649" strokeWidth="1" />
      </svg>

      {/*<span>Back</span>*/}
    </Link>
    <ul className={internalHeaderStyle.contactList}>
      <li>
        <a
          href="https://www.twitter.com/pedromurad"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.20747 21C8.25612 21 7.27691 20.923 6.29183 20.7686C4.55681 20.4965 2.80048 19.5892 2.30349 19.3175L1 18.6051L2.41124 18.1411C3.95391 17.6341 4.89233 17.3195 6.05399 16.8268C4.89082 16.2637 3.99436 15.2507 3.56283 13.9413L3.23453 12.9449L3.50375 12.986C3.24846 12.7288 3.04687 12.4677 2.89061 12.2303C2.33487 11.3865 2.04064 10.3554 2.10325 9.47176L2.16501 8.60209L2.68584 8.80378C2.46613 8.38899 2.30735 7.94602 2.21403 7.48173C1.9866 6.34946 2.17693 5.14655 2.75012 4.09466L3.20381 3.26207L3.81057 3.99063C5.72955 6.2951 8.16012 7.66211 11.0449 8.0623C10.927 7.25035 11.0153 6.46675 11.3076 5.76437C11.6478 4.94655 12.253 4.25306 13.0573 3.75874C13.9506 3.20988 15.0339 2.94393 16.1076 3.00987C17.2468 3.07984 18.281 3.50654 19.1025 4.24467C19.5036 4.14047 19.7992 4.02922 20.1987 3.87888C20.439 3.78844 20.7116 3.68575 21.0527 3.56829L22.3095 3.13538L21.4899 5.47543C21.5439 5.4709 21.5997 5.46704 21.6579 5.46452L23 5.40378L22.2068 6.48756C22.1613 6.54964 22.1497 6.56726 22.1336 6.59159C22.0696 6.6879 21.9901 6.80771 20.9013 8.26113C20.6287 8.62508 20.4926 9.09909 20.5179 9.5961C20.6144 11.4848 20.3826 13.1936 19.8286 14.6749C19.3045 16.0763 18.4925 17.2787 17.4153 18.2487C16.0823 19.4488 14.3825 20.2704 12.363 20.6906C11.3723 20.8966 10.3085 21 9.20747 21V21Z"
              fill="#81B649"
            />
          </svg>
        </a>
      </li>
      <li>
        <a href="https://www.linkedin.com/in/pedro-pim-80516822/">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1 1V23H23V1H1ZM7.84282 19.277H4.5788V9.45699H7.84282V19.277ZM6.21081 8.11669H6.18924C5.09432 8.11669 4.38462 7.36235 4.38462 6.41949C4.38462 5.45594 5.11506 4.72298 6.23218 4.72298C7.34954 4.72298 8.03618 5.45594 8.05755 6.41949C8.05755 7.36235 7.34954 8.11669 6.21081 8.11669ZM19.6154 19.277H16.3505V14.0233C16.3505 12.7037 15.8788 11.803 14.6971 11.803C13.7947 11.803 13.2584 12.4094 13.0222 12.9969C12.9363 13.2068 12.9138 13.4985 12.9138 13.7927V19.2771H9.64832C9.64832 19.2771 9.69208 10.3783 9.64832 9.45704H12.9138V10.8486C13.3477 10.181 14.122 9.2265 15.8563 9.2265C18.0049 9.2265 19.6154 10.6297 19.6154 13.6457V19.277Z"
              fill="#81B649"
            />
          </svg>
        </a>
      </li>
      {/*<li>
                <a href='mailto:pedromurad@gmail.com'>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0467 13.1748L1.06104 3.5625H23.0324L12.0467 13.1748ZM8.47448 12L0 19.4153V4.58475L8.47448 12ZM12.0468 15.0439L14.4576 12.9344L23.0328 20.4375H1.06104L9.63593 12.9344L12.0468 15.0439ZM15.5259 12L24.0003 19.415V4.58484L15.5259 12Z" fill="#660AFF" />
                        </g>
                        <defs>
                            <clipPath id="clip0">
                                <rect width="24" height="24" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </a>
            </li>*/}
    </ul>
  </header>
)

export default InternalHeader
