import React from 'react'
import InternalHeader from '../components/InternalHeader'
import internalLayoutStyle from './internal-layout.module.scss'



const InternalLayout = (props) => {
    return (
        <div className={internalLayoutStyle.container}>
            <InternalHeader />
            {props.children}
        </div>
    )
}

export default InternalLayout